import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Item from "../components/Item"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

import "../utils/image"

export const query = graphql`
  query {
    ploermel: file(relativePath: { eq: "ploermel.jpg" }) {
      ...squareImage
    }
    vannes: file(relativePath: { eq: "vannes.jpg" }) {
      ...squareImage
    }
  }
`

const Vente = () => {
  const description = `Les fromages sont vendus sur les marchés de Ploërmel le vendredi matin et de Vannes le samedi matin.`

  return (
    <Layout>
      <SEO title="La Vente" description={description} />
      <div style={{ marginBottom: "1.8rem" }}>
        <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
          La Vente
        </h1>
        <p style={{ textAlign: "justify" }}>{description}</p>
      </div>
      <StaticQuery
        query={query}
        render={({ ploermel, vannes }) => (
          <>
            <Item
              image={ploermel}
              title="Ploërmel"
              text={<p>Le marché de Ploërmel</p>}
            ></Item>
            <Item
              image={vannes}
              title="Vannes"
              text={<p>Le marché de Vannes</p>}
              left
            ></Item>
          </>
        )}
      />
    </Layout>
  )
}

export default Vente
